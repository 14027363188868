import React, { forwardRef } from 'react'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import Logo from '../assets/images/raindrop_logo.svg'

const PackageWrapper = styled.article`
  .header {
    display: flex;
    justify-content: space-between;
  }
  position: relative;

  max-width: 500px;
  min-width: 375px;

  width: 80vw;

  @media (min-width: 600px) {
    width: 50vw;
  }

  @media (min-width: 1000px) {
    min-width: 400px;
    width: 25vw;
  }

  .content {
    background: #071741;
    color: #071741;
    text-align: center;
    padding: 16px;
    border-radius: 4px;
    height: auto;

    h2 {
      font-family: 'GothamHTF', 'Helvetica Neue', sans-serif;
    }
    h4 {
      font-family: 'GothamHTF', 'Helvetica Neue', sans-serif;
    }
    ul {
      padding-left: 0;
      list-style: none;
      margin-bottom: 12px;
      margin-top: 0px;
      text-align: left;

      li {
        line-height: 26px;
        padding-left: 0.5rem;
      }

      .linethrough {
        text-decoration: line-through;
        opacity: 0.25;
      }
    }
  }

  z-index: 0;
  font-size: 16px;
  .content {
    background: white;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &::before {
    content: '';
    position: absolute;
    top: -8px;
    right: -11px;
    bottom: -8px;
    left: -11px;

    @media (min-width: 400px) {
      background: linear-gradient(to right, #75cdf5, #047cc2);
      z-index: -1;
      transform: skew(1deg, 1deg);
    }
    border-radius: 4px;
    border-radius: 32px;
  }
  .title {
    flex: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .title-text {
    color: #34a1d5;
    font-size: 30px;
    font-weight: 800;
    flex: 0;
    text-align: left;
    margin-left: 10px;
    margin-bottom: 6px;
  }
  .logo {
    height: 20px;
    width: 20px;
    margin-top: 28px;
    margin-bottom: 0;
    margin-left: 2px;
    margin-right: 2px;
  }
`

const PackageWrapperFree = styled.article`
  position: relative;
  max-width: 367px;
  min-width: 367px;
  margin-bottom: 3rem;
  width: 367px;
  @media (min-width: 600px) {
    width: 50vw;
  }
  @media (min-width: 1000px) {
    width: 25vw;
  }
  .content {
    background: #071741;
    color: #071741;
    text-align: center;
    padding: 16px;
    border-radius: 4px;
    h2 {
      font-family: 'GothamHTF', 'Helvetica Neue', sans-serif;
    }
    h4 {
      font-family: 'GothamHTF', 'Helvetica Neue', sans-serif;
    }
    ul {
      padding-left: 12px;
      list-style: none;
      margin-bottom: 12px;
      margin-top: 0px;
      text-align: left;

      li {
        line-height: 26px;
        padding-left: 0.5rem;
      }

      .linethrough {
        text-decoration: line-through;
        opacity: 0.25;
      }
    }
  }
  z-index: 0;
  font-size: 16px;
  .content {
    background: white;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &::before {
    content: '';
    position: absolute;
    top: -8px;
    right: -11px;
    bottom: -8px;
    left: -11px;

    @media (min-width: 400px) {
      background: linear-gradient(to right, #75cdf5, #047cc2);
      z-index: -1;
      transform: skew(1deg, 1deg);
    }

    border-radius: 4px;
    border-radius: 32px;
  }
`

export const Package = forwardRef((props: any, ref: any) => {
  const rdIcons: number[] = []
  if (props.raindropiconcount > 0) for (let i = 0; i < props.raindropiconcount; i++) rdIcons.push(i)
  return (
    <PackageWrapper>
      <div className='content' ref={ref}>
        <div>
          <div className='header'>
            <div className='title'>
              {rdIcons.map((idx) => {
                return <img key={'rdicon-' + props.title + '-' + idx} className='logo' src={Logo} alt='Raindrop Logo' />
              })}
              <p className='title-text'>{props.title}</p>
            </div>
            <div className='pricing-info'>
              {props.monthlyprice && (
                <div style={{ display: 'flex', alignSelf: 'right', justifyContent: 'flex-end' }}>
                  <Typography style={{ paddingRight: 6, fontSize: 12, margin: 0 }}>Starts at</Typography>
                  {props.discountOn && (
                    <Typography style={{ textDecoration: 'line-through', fontSize: 12, margin: 0 }}>
                      ${props.monthlyprice}/mo
                    </Typography>
                  )}
                </div>
              )}
              {props.discountmonthlyprice && props.discountOn && (
                <Typography
                  style={{ color: '#00adff', fontWeight: 'bold', margin: 0, fontSize: 22, textAlign: 'right' }}>
                  ${props.discountmonthlyprice}/mo
                </Typography>
              )}
              {props.monthlyprice && !props.discountOn && (
                <Typography
                  style={{ color: '#00adff', fontWeight: 'bold', margin: 0, fontSize: 22, textAlign: 'right' }}>
                  ${props.monthlyprice}/mo
                </Typography>
              )}
              {props.yearlyprice && props.discountOn && (
                <>
                  <div style={{ display: 'flex', alignSelf: 'center' }}>
                    <Typography style={{ paddingRight: 6, fontSize: 12, margin: 0 }}>billed at</Typography>
                    <Typography style={{ textDecoration: 'line-through', fontSize: 12, margin: 0, paddingRight: 6 }}>
                      ${props.yearlyprice}
                    </Typography>
                    {props.discountyearlyprice && (
                      <Typography style={{ fontSize: 12, margin: 0 }}>${props.discountyearlyprice}/yr</Typography>
                    )}
                  </div>
                </>
              )}
              {props.monthlyprice && !props.discountOn && (
                <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                  <Typography style={{ fontSize: 12, margin: 0, paddingRight: 4 }}>billed monthly</Typography>
                </div>
              )}
            </div>
          </div>
        </div>
        {props.children}
      </div>
    </PackageWrapper>
  )
})

export const PackageFree = (props: any) => {
  return (
    <PackageWrapperFree>
      <div className='content'>
        <div>
          <h2>{props.title}</h2>
        </div>
        {props.children}
      </div>
    </PackageWrapperFree>
  )
}
