import { motion, useTransform, useViewportScroll } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

const PackagesWrapper = styled.section`
  text-align: center;
  width: 100%;
  .text-area {
    width: 80vw;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 1200px) {
      max-width: 700px;
    }
  }
  h2 {
    color: #34a1d5;
  }
  p {
    color: #071840;
  }
  .flex-container {
    flex-direction: column-reverse;

    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 3vh;

    @media (min-width: 992px) {
      align-items: flex-start;
      flex-direction: row;
    }
  }
`

export const Packages = (props: any) => {
  const { scrollYProgress } = useViewportScroll()
  return (
    <PackagesWrapper>
      <div className='flex-container'>{props.children}</div>
    </PackagesWrapper>
  )
}
