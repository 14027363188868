import React from 'react'
import { LinkHubspot } from './LinkHubspot'
import { ButtonCompactWithLogo } from './Button'

export interface SignUpButtonProps {
  label: string
  style?: any
  onClick?: any
}

export const SignUpButton = (props: SignUpButtonProps) => {
  return (
    <LinkHubspot to="https://share.hsforms.com/1wzfrY5DmSsa2666ecHtWvw4702y" style={props.style}>
      <span className="sr-only">Jump to Signup</span>
      <ButtonCompactWithLogo cta={props.label} label={props.label} onClick={props.onClick} />
    </LinkHubspot>
  )
}
